<template>

<div style="position: relative;">
    <div  :class="dense == true ? 'gallery-dense' : 'gallery'">

          <div
          class="pa-0"
            v-for="(item, index) in data"
            :key="('gallery_' + (data.id || index))"
            xs="12"
            sm="12"
            md="6"
            lg="6"
          >
           <v-hover :disabled="($vuetify.breakpoint.xsOnly || $vuetify.breakpoint.smOnly)"
        v-slot="{ hover }"

      >
          <div class="item-gallery elevation-0" v-on:click="$emit('hover-click', item)">
              <div style="border-radius: 5px!important; max-width: 100%; max-height: 100%;">
            <slot  name="content" :index="index" :hover="hover" :data="item"/>
                </div>

            <v-overlay v-show="!noOverlay" style="z-index: 50!important;" opacity=".3" color="white" absolute :value="hover">
                <slot name="overlay" :index="index" :data="item"/>

            </v-overlay>
            </div>
             </v-hover>

          </div>

</div>
 <!-- <div v-show="refreshTime != undefined"  style="position: absolute; bottom: -35px; right: 15px; ">
     <v-row class="ma-0" align="center">

        Letzte Aktualisierung: <b class="ml-1">{{$helper.formatDate(refreshTime)}}</b>
        <IconButton :loading="refreshLoading" v-on:click="refreshDataEvent" color="primary" dense icon="mdi-refresh"/>

     </v-row>
     </div> -->
</div>
</template>

<script>
// import IconButton from "../button/IconButton.vue";


export default {

    props: {

        dense: {
          type: Boolean,
          default: false
        },
        noOverlay: {
          type: Boolean,
          default: false,
        },
        data: {
            style: Array,
            required: true,
        },

        refreshTime: {
            type: [String, Number],
            required: false,
            default: undefined
        }
    },

    components: {
        // IconButton
    },

    data() {
        return {
            refreshLoading: false,
        }
    },

    watch: {
        data: {
            handler() {
                
            }, immediate: true, deep: true
        },  
        refreshTime() {
            this.refreshLoading = false
        }
    },

    methods: {
        refreshDataEvent() {
            this.refreshLoading = true;
            this.$emit('refresh');
        }
    }



}

</script>

<style>
.item-gallery {
    position: relative;
    background-color: rgba(230, 230, 230,1);
    aspect-ratio: 16 / 9;
    border-radius: 5px;
    cursor: pointer;
}
.gallery {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(28rem, 1fr));
  grid-gap: 1rem;
  /* max-width: 100%; */

  margin: 0 auto;
  padding: 0 1rem;
  padding-bottom: 10px;
}

.gallery-dense {
  display: grid;
  padding-bottom: 10px;
  grid-template-columns: repeat(auto-fill, minmax(20rem, 1fr));
  grid-gap: 1rem;
  /* max-width: 100rem; */

  padding: 0 .1rem;
  margin: 0 auto;
  padding-bottom: 10px;
}

.item-gallery v-img {
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
</style>
